<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="12" class="pt-0">
        <v-tabs
          background-color="color3"
          slider-color="color3Text"
        >
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
            exact
            :to="tab.to"
            class="color3Text--text"
            active-class="color3"
          >{{tab.text}}</v-tab>
        </v-tabs>
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['username'],
  computed: {
    ...mapGetters([
      'user',
      'theme',
      'getPageInfo',
      'color1IsDark',
      'organization'
    ]),
    userIsAdmin () {
      return this.user && this.user.isPageAdmin(this.username)
    },
    page () {
      return this.getPageInfo(this.username)
    },
    tabs () {
      return [
        { text: 'Home', to: { name: 'cart-manager', params: { username: this.username } }, show: true },
        { text: 'Promos', to: { name: 'cart-manager-promos', params: { username: this.username } }, show: true },
        { text: 'Refund Policies', to: { name: 'cart-manager-refund-policies', params: { username: this.username } }, show: true },
        { text: 'Products', to: { name: 'cart-manager-products', params: { username: this.username } }, show: true }
      ].filter(f => f.show)
    }
  }
}
</script>

<style>

</style>
